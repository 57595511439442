<template>
<div>
    <b-row>
        <b-col md="12">
            <b-row>
                <b-col md="12">
                    <CCard>

                        <CCardHeader color="primario" text-color="white">
                            <i class="fas fa-digital-tachograph fa-md mr-1"></i> <span class="h5"> Gestión de reporte de no conformidad</span>
                        </CCardHeader>
                        <CCardBody>
                            <b-row>
                                <b-col md="12">
                                    <validation-observer ref="observer" v-slot="{ handleSubmit }">
                                        <b-form @submit.stop.prevent="handleSubmit(registrarReportNC)">
                                            <b-row>
                                                <b-col md="6">
                                                    <validation-provider name="normas" rules="required" v-slot="{errors}">
                                                        <b-form-group label="Normas:" class="mb-2">
                                                            <v-select multiple :reduce="listaNormas => listaNormas.idNorma" label="descripcion" placeholder="Seleccione una opción" :class="{'style-valid-select': dataReporteNC.normas.length > 0  , 'style-invalid-select is-invalid': !!errors.length}" v-model.lazy="dataReporteNC.normas" :options="listaNormas">
                                                                <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                            </v-select>
                                                            <div class="invalid-feedback">{{ errors[0]}}</div>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <!-- <b-col md="6">
                                                    <validation-provider name="cliente" rules="required" v-slot="{errors}">
                                                        <b-form-group label="Cliente:" class="mb-2">
                                                            <v-select :reduce="listaClientes => listaClientes.idAuditoria" label="razonSocial" placeholder="Seleccione una opción" :class="{'style-valid-select': dataReporteNC.idAuditoria  , 'style-invalid-select is-invalid': !!errors.length}" v-model.lazy="dataReporteNC.idAuditoria" :options="listaClientes">
                                                                <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                            </v-select>
                                                            <div class="invalid-feedback">{{ errors[0]}}</div>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col> -->
                                                <b-col md="6">
                                                    <validation-provider name="contrato Nro" :rules="{required: !isTemplate}" v-slot="validationContext">
                                                        <b-form-group label="Contrato Nro:" class="mb-2">
                                                            <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el nro de contrato" v-model.lazy="dataReporteNC.nContrato"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>

                                                <b-col md="12">
                                                    <b-table-simple bordered show-empty mediun responsive outlined>
                                                        <b-tbody>
                                                            <b-tr>
                                                                <b-td class="valign-middle">
                                                                    <span class="text-muted h6"><b>NCR No:</b></span>
                                                                </b-td>
                                                                <b-td class="text-center valign-middle">
                                                                    <b-form-group label="Fecha:">
                                                                        <b-form-input @change="dataReporteNC.numeroNCR[0].fecha  = $event" :value="dataReporteNC.numeroNCR[0].fecha" type="date" />
                                                                    </b-form-group>
                                                                </b-td>
                                                                <b-td class="text-center  valign-middle">
                                                                    <span class="text-muted h6"><b>Categoría NCR</b></span>
                                                                </b-td>
                                                                <b-td class="text-center valign-middle">
                                                                    <b-form-checkbox plain v-model.lazy="dataReporteNC.numeroNCR[0].fecha" value="1" unchecked-value="0">
                                                                        Menor
                                                                    </b-form-checkbox>
                                                                </b-td>
                                                                <b-td class="text-center valign-middle ">
                                                                    <b-form-checkbox plain v-model.lazy="dataReporteNC.numeroNCR[0].importante" value="1" unchecked-value="0">
                                                                        Importante
                                                                    </b-form-checkbox>
                                                                </b-td>
                                                            </b-tr>
                                                            <b-tr>
                                                                <b-td class="text-center valign-middle ">
                                                                    <validation-provider name=" " :rules="{ required: !isTemplate }" v-slot="validationContext">
                                                                        <b-form-select :state="getValidationState(validationContext)" value-field="id" text-field="descripcion" v-model="dataReporteNC.sgc" :options="listaSGC">
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </validation-provider>
                                                                </b-td>
                                                                <b-td class="text-center valign-middle " colspan="4">
                                                                    <b-form-textarea rows="2" max-rows="6" placeholder="Escriba aqui..." :value="dataReporteNC.detalleSgc" @change="dataReporteNC.detalleSgc = $event" />
                                                                </b-td>
                                                            </b-tr>

                                                        </b-tbody>
                                                    </b-table-simple>
                                                </b-col>

                                                <b-col md="4">
                                                    <validation-provider name="metodología de auditoría" :rules="!isTemplate ? 'required': ''" v-slot="{errors}">
                                                        <b-form-group label="Metodología de auditoría:" class="mb-2">
                                                            <v-select :reduce="listaMetodologiaAuditoria => listaMetodologiaAuditoria.idMetodologiaAuditoria" label="descripcion" placeholder="Seleccione una opción" :class="{'style-valid-select': dataReporteNC.metodologiaAuditoria  , 'style-invalid-select is-invalid': !!errors.length}" v-model.lazy="dataReporteNC.metodologiaAuditoria" :options="listaMetodologiaAuditoria">
                                                                <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                            </v-select>
                                                            <div class="invalid-feedback">{{ errors[0]}}</div>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="4">
                                                    <validation-provider name="auditor líder" :rules="{required: !isTemplate}" v-slot="validationContext">
                                                        <b-form-group label="Auditor líder:" class="mb-2">
                                                            <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el auditor líder" v-model.lazy="dataReporteNC.auditorLider"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="4">
                                                    <validation-provider name="auditado" :rules="{required: !isTemplate}" v-slot="validationContext">
                                                        <b-form-group label="Auditado:" class="mb-2">
                                                            <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el auditado" v-model.lazy="dataReporteNC.auditado"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="4">
                                                    <validation-provider name="auditor" :rules="{required: !isTemplate}" v-slot="validationContext">
                                                        <b-form-group label="Auditor:" class="mb-2">
                                                            <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el auditor" v-model.lazy="dataReporteNC.auditor"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="4">
                                                    <validation-provider name="proceso auditado" :rules="{required: !isTemplate}" v-slot="validationContext">
                                                        <b-form-group label="Proceso auditado:" class="mb-2">
                                                            <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el proceso auditado" v-model.lazy="dataReporteNC.procesoAuditado"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="4">
                                                    <validation-provider name="no de cláusula" :rules="{required: !isTemplate}" v-slot="validationContext">
                                                        <b-form-group label="No de cláusula:" class="mb-2">
                                                            <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el no de cláusula" v-model.lazy="dataReporteNC.noClausula"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="4">
                                                    <validation-provider name="referencia de documento del cliente" :rules="{required: !isTemplate}" v-slot="validationContext">
                                                        <b-form-group label="Referencia de documento del cliente:" class="mb-2">
                                                            <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el referencia de documento del cliente" v-model.lazy="dataReporteNC.referenciaDocumento"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="4">
                                                    <validation-provider name="signo del auditor" :rules="{required: !isTemplate}" v-slot="validationContext">
                                                        <b-form-group label="Signo del auditor:" class="mb-2">
                                                            <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el signo del auditor" v-model.lazy="dataReporteNC.signoAuditor"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="4">
                                                    <validation-provider name="signo del auditado" :rules="{required: !isTemplate}" v-slot="validationContext">
                                                        <b-form-group label="Signo del auditado:" class="mb-2">
                                                            <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el signo del auditado" v-model.lazy="dataReporteNC.signoAuditado"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="6">
                                                    <validation-provider name="criterios de auditoría" :rules="{required: !isTemplate}" v-slot="validationContext">
                                                        <b-form-group label="Criterios de auditoría:" class="mb-2">
                                                            <b-form-textarea rows="2" :state="getValidationState(validationContext)" max-rows="6" placeholder="ingrese los criterios de auditoría" :value="dataReporteNC.criteriosAuditoria" @change="dataReporteNC.criteriosAuditoria = $event" />
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="6">
                                                    <validation-provider name="descripción de la no conformidad" :rules="{required: !isTemplate}" v-slot="validationContext">
                                                        <b-form-group label="Descripción de la no conformidad:" class="mb-2">
                                                            <b-form-textarea rows="2" :state="getValidationState(validationContext)" max-rows="6" placeholder="ingrese la descripción de la no conformidad" :value="dataReporteNC.descripcionNoConformidad" @change="dataReporteNC.descripcionNoConformidad = $event" />
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="6">
                                                    <validation-provider name="causa principal" :rules="{required: !isTemplate}" v-slot="validationContext">
                                                        <b-form-group label="Causa principal:" class="mb-2">
                                                            <b-form-textarea rows="2" :state="getValidationState(validationContext)" max-rows="6" placeholder="ingrese la causa principal" :value="dataReporteNC.causaPrincipal" @change="dataReporteNC.causaPrincipal = $event" />
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="6">
                                                    <validation-provider name="corrección" :rules="{required: !isTemplate}" v-slot="validationContext">
                                                        <b-form-group label="Corrección:" class="mb-2">
                                                            <b-form-textarea rows="2" :state="getValidationState(validationContext)" max-rows="6" placeholder="ingrese la corrección" :value="dataReporteNC.correccion" @change="dataReporteNC.correccion = $event" />
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="12" class="mt-2">
                                                    <b-table-simple bordered show-empty mediun responsive outlined>
                                                        <b-tbody>
                                                            <b-tr>
                                                                <b-td class="text-center valign-middle" colspan="2">
                                                                    <b-form-checkbox-group class="mb-2" v-model.lazy="dataReporteNC.lugarAuditoria" :options="[{ value: 1, text: 'En el sitio' },{ value: 2, text: 'Auditoría remota' },]" small plain></b-form-checkbox-group>
                                                                </b-td>
                                                                <b-td class="text-center valign-middle" colspan="6">
                                                                    <b-form-group label="Fecha objetivo para la implementación de la acción correctiva propuesta:">
                                                                        <b-form-input @change="dataReporteNC.fechaObjetivo  = $event" :value="dataReporteNC.fechaObjetivo" type="date" />
                                                                    </b-form-group>
                                                                </b-td>
                                                            </b-tr>
                                                            <b-tr>
                                                                <b-td class="text-center valign-middle" colspan="8">
                                                                    <b-form-textarea rows="2" max-rows="6" placeholder="Escriba aqui..." :value="dataReporteNC.detalleObjetivo" @change="dataReporteNC.detalleObjetivo = $event" />
                                                                </b-td>
                                                            </b-tr>
                                                        </b-tbody>
                                                    </b-table-simple>
                                                    <b-table-simple bordered show-empty mediun responsive outlined>
                                                        <b-tbody>
                                                            <b-tr>
                                                                <b-td class="text-center valign-middle" width="20%">
                                                                    <b-form-group label="Auditor:">
                                                                        <b-form-input @change="dataReporteNC.tAuditor  = $event" :value="dataReporteNC.tAuditor" placeholder="Ingrese el auditor" />
                                                                    </b-form-group>
                                                                </b-td>
                                                                <b-td class="text-center valign-middle" width="25%">
                                                                    <b-form-group label="Firma:">
                                                                        <b-input-group>
                                                                            <b-input-group-prepend v-if="dataReporteNC.urlFirma1 != ''">
                                                                                <b-button variant="dark" class="px-2 py-0" @click="descargarDocumento(dataReporteNC.urlFirma1)" v-c-tooltip="'Descargar'">
                                                                                    <i class="fas fa-download fa-xs"></i>
                                                                                </b-button>
                                                                            </b-input-group-prepend>
                                                                            <b-form-file ref="file1" class="text-left" v-model.lazy="firma1" v-on:change="handleFileUpload('1',$event)" :placeholder="dataReporteNC.nombreFirma1 || 'Elija un archivo o arrástrelo aquí...'" :drop-placeholder="dataReporteNC.nombreFirma1 || 'Elija un archivo o arrástrelo aquí...'" browse-text="Subir"></b-form-file>
                                                                        </b-input-group>
                                                                    </b-form-group>
                                                                </b-td>
                                                                <b-td class="text-center valign-middle" width="10%">
                                                                    <b-form-group label="Fecha:">
                                                                        <b-form-input @change="dataReporteNC.tFecha1  = $event" :value="dataReporteNC.tFecha1" type="date" />
                                                                    </b-form-group>
                                                                </b-td>
                                                                <b-td class="text-center valign-middle" width="10%">
                                                                    <span class="text-muted h6"><b>Revisado y aceptado por el auditor </b></span>
                                                                </b-td>
                                                                <b-td class="text-center valign-middle" width="25%">
                                                                    <b-form-group label="Firma:">
                                                                        <b-input-group>
                                                                            <b-input-group-prepend v-if="dataReporteNC.urlFirma2 != ''">
                                                                                <b-button variant="dark" class="px-2 py-0" @click="descargarDocumento(dataReporteNC.urlFirma2)" v-c-tooltip="'Descargar'">
                                                                                    <i class="fas fa-download fa-xs"></i>
                                                                                </b-button>
                                                                            </b-input-group-prepend>
                                                                            <b-form-file ref="file2" class="text-left" v-model.lazy="firma2" v-on:change="handleFileUpload('2',$event)" :placeholder="dataReporteNC.nombreFirma2 || 'Elija un archivo o arrástrelo aquí...'" :drop-placeholder="dataReporteNC.nombreFirma2 || 'Elija un archivo o arrástrelo aquí...'" browse-text="Subir"></b-form-file>
                                                                        </b-input-group>
                                                                    </b-form-group>
                                                                </b-td>
                                                                <b-td class="text-center valign-middle" width="10%">
                                                                    <b-form-group label="Fecha:">
                                                                        <b-form-input @change="dataReporteNC.tfecha2  = $event" :value="dataReporteNC.tfecha2" type="date" />
                                                                    </b-form-group>
                                                                </b-td>
                                                            </b-tr>
                                                            <b-tr>
                                                                <b-td class="valign-middle" colspan="8">
                                                                    <b-form-group label="Verificación de acción correctiva (La efectividad de la acción correctiva se verificará durante la auditoría posterior):">
                                                                        <b-form-textarea rows="2" max-rows="6" placeholder="Escriba aqui..." :value="dataReporteNC.verificacionAccionCorrectiva" @change="dataReporteNC.verificacionAccionCorrectiva = $event" />
                                                                    </b-form-group>
                                                                </b-td>
                                                            </b-tr>
                                                        </b-tbody>
                                                    </b-table-simple>
                                                    <b-table-simple bordered show-empty mediun responsive outlined>
                                                        <b-tbody>
                                                            <b-tr>
                                                                <b-td class="valign-middle text-center" width="25%">
                                                                    <b-form-group label="Verificada por:">
                                                                        <b-form-input @change="dataReporteNC.verificadaPor  = $event" :value="dataReporteNC.verificadaPor" placeholder="Ingrese el verificador" />
                                                                    </b-form-group>
                                                                </b-td>
                                                                <b-td class="valign-middle text-center" width="35%">
                                                                    <b-form-group label="Firma del auditor:">
                                                                        <b-input-group>
                                                                            <b-input-group-prepend v-if="dataReporteNC.urlFirma3 != ''">
                                                                                <b-button variant="dark" class="px-2 py-0" @click="descargarDocumento(dataReporteNC.urlFirma3)" v-c-tooltip="'Descargar'">
                                                                                    <i class="fas fa-download fa-xs"></i>
                                                                                </b-button>
                                                                            </b-input-group-prepend>
                                                                            <b-form-file ref="file3" class="text-left" v-model.lazy="firma3" v-on:change="handleFileUpload('3',$event)" :placeholder="dataReporteNC.nombreFirma3 || 'Elija un archivo o arrástrelo aquí...'" :drop-placeholder="dataReporteNC.nombreFirma3 || 'Elija un archivo o arrástrelo aquí...'" browse-text="Subir"></b-form-file>
                                                                        </b-input-group>
                                                                    </b-form-group>
                                                                </b-td>
                                                                <b-td class="valign-middle text-center" width="20%">
                                                                    <b-form-group label="Fecha:">
                                                                        <b-form-input @change="dataReporteNC.tFecha3  = $event" :value="dataReporteNC.tFecha3" type="date" />
                                                                    </b-form-group>
                                                                </b-td>
                                                                <b-td class="valign-middle text-center" width="20%">
                                                                    <b-form-group label="NCR cerrado:">
                                                                        <b-form-radio-group class="mb-2" v-model.lazy="dataReporteNC.ncrCerrado" :options="[{ value: 1, text: 'Si' },{ value: 2, text: 'No' },]" small plain />
                                                                    </b-form-group>
                                                                </b-td>
                                                            </b-tr>
                                                        </b-tbody>
                                                    </b-table-simple>
                                                </b-col>
                                                <b-col md="12">
                                                    <span>{{dataReporteNC.nota}}</span>
                                                </b-col>
                                            </b-row>
                                            <b-row class="text-center mt-2">
                                                <b-col md="12">
                                                    <b-button :to="{name: $route.params.eu ? 'ejecutar auditoria' : 'Documentos Stage 2' }" class="mr-2" variant="dark" type="button">
                                                        <i class="fas fa-arrow-left"></i> Volver
                                                    </b-button>
                                                    <b-button variant="success" type="submit">
                                                        <i class="fas fa-save"></i> Guardar
                                                    </b-button>
                                                </b-col>
                                            </b-row>
                                        </b-form>
                                    </validation-observer>
                                </b-col>
                            </b-row>
                        </CCardBody>
                    </CCard>
                </b-col>
            </b-row>
        </b-col>
    </b-row>

</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";

export default {

    data() {
        return {
            isTemplate: true,

            dataReporteNC: {
                idReporteNC: '',
                idCliente: null,

                idAuditoria: null,
                normas: [],
                nContrato: '',

                numeroNCR: [{
                    fecha: '',
                    categoriaNCR: '',
                    menor: 0,
                    importante: 0
                }],

                sgc: null,
                detalleSgc: 'Tipo de auditoría: IA_Stage II',
                metodologiaAuditoria: null,
                auditorLider: '',
                auditado: '',
                auditor: '',
                procesoAuditado: '',
                criteriosAuditoria: '',
                noClausula: '',
                referenciaDocumento: '',
                descripcionNoConformidad: '',
                signoAuditor: '',
                signoAuditado: '',
                causaPrincipal: '',
                correccion: '',
                accionCorrectiva: 0,
                fechaObjetivo: '',
                lugarAuditoria: [],

                tAuditor: '',
                nombreFirma1: '',
                urlFirma1: '',
                tFecha2: '',
                nombreFirma2: '',
                urlFirma2: '',
                verificacionAccionCorrectiva: '',
                verificadaPor: '',
                nombreFirma3: '',
                urlFirma3: '',
                tFecha3: '',
                ncrCerrado: 1,
                nota: 'La acción correctiva / acción correctiva propuesta se proporcionará dentro de los 30 días calendario posteriores a la fecha de la reunión de cierre. Cuando se proporcione la acción correctiva propuesta, se'
            },

            firma1: null,
            firma2: null,
            firma3: null,

            listaClientes: [],
            listaNormas: [{
                    idNorma: 1,
                    descripcion: 'ISO 9001: 2015'
                },
                {
                    idNorma: 2,
                    descripcion: 'ISO 14001: 2015'
                },
                {
                    idNorma: 3,
                    descripcion: 'ISO 45001: 2018'
                },
                {
                    idNorma: 4,
                    descripcion: 'ISO 37001: 2016'
                },
                {
                    idNorma: 5,
                    descripcion: 'ISO 27001: 2013'
                },
                {
                    idNorma: 6,
                    descripcion: 'ISO 20000: 2011'
                },
            ],

            listaMetodologiaAuditoria: [{
                idMetodologiaAuditoria: 1,
                descripcion: 'Auditoría in situ',
            }, {
                idMetodologiaAuditoria: 2,
                descripcion: 'Auditoría remota',
            }],

            listaTipoAuditoria: [{
                idTipoAuditoria: 1,
                descripcion: 'IA-Estadio-II',
            }],
            listaSGC: [{
                    id: 1,
                    descripcion: 'SGC'
                },
                {
                    id: 2,
                    descripcion: 'ABMS'
                },
                {
                    id: 3,
                    descripcion: 'EMS / OHSAS'
                }, {
                    id: 4,
                    descripcion: 'SGSI'
                }
            ],

            listaNContrato: [{
                idNContrato: 1,
                descripcion: 'JAS-ANZ'
            }, {
                idNContrato: 2,
                descripcion: 'IAS'
            }],
        }

    },
    methods: {

        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        handleFileUpload(ident, e) {
            if (!e.target.files[0]) {
                return;
            } else {
                if (!e.target.files[0].name.match(/\.(xlsx|xls|csv|pdf|doc|docx|ppt|pptx|png|jpg|jpeg)$/i)) {
                    this.swat('error', "Formato de archivo no admitido.");
                    this.$refs[`file${ident}`].reset();
                    return;
                }
                if (e.target.files[0].size > 20971520) {
                    this.swat('error', "El tamaño del archivo no debe ser superior a 20MB");
                    this.$refs[`file${ident}`].reset();
                    return;
                }
            }
        },

        listarClientes() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "cliente/comercial/listar-clientes-iqc", {
                    params: {
                        estado: 2,
                        idCliente: me.$store.state.user.uidClient
                    }
                })
                .then(function (response) {
                    me.listaClientes = response.data.filter(x => x.estado == 1 || x.estado == 2);

                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!' + error)
                });
        },
        obtenerReporteNCS2() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "cliente/pack/obtener-reporte-nc-s2", {
                    params: {
                        idReporteNC: me.$route.params.id,
                        idCliente: me.dataReporteNC.idCliente
                    }
                })
                .then(function (response) {

                    me.dataReporteNC.idReporteNC = response.data[0].idReporteNC;
                    me.dataReporteNC.idCliente = response.data[0].idCliente;
                    me.dataReporteNC.idAuditoria = response.data[0].idAuditoria;
                    me.dataReporteNC.normas = JSON.parse(response.data[0].normas);
                    me.dataReporteNC.nContrato = response.data[0].nContrato;
                    me.dataReporteNC.numeroNCR = JSON.parse(response.data[0].numeroNCR);

                    me.dataReporteNC.sgc = response.data[0].sgc;
                    me.dataReporteNC.detalleSgc = response.data[0].detalleSgc;
                    me.dataReporteNC.metodologiaAuditoria = response.data[0].metodologiaAuditoria;
                    me.dataReporteNC.auditorLider = response.data[0].auditorLider;
                    me.dataReporteNC.auditado = response.data[0].auditado;
                    me.dataReporteNC.auditor = response.data[0].auditor;
                    me.dataReporteNC.procesoAuditado = response.data[0].procesoAuditado;
                    me.dataReporteNC.criteriosAuditoria = response.data[0].criteriosAuditoria;
                    me.dataReporteNC.noClausula = response.data[0].noClausula;
                    me.dataReporteNC.referenciaDocumento = response.data[0].referenciaDocumento;
                    me.dataReporteNC.descripcionNoConformidad = response.data[0].descripcionNoConformidad;
                    me.dataReporteNC.signoAuditor = response.data[0].signoAuditor;
                    me.dataReporteNC.signoAuditado = response.data[0].signoAuditado;
                    me.dataReporteNC.causaPrincipal = response.data[0].causaPrincipal;
                    me.dataReporteNC.correccion = response.data[0].correccion;
                    me.dataReporteNC.accionCorrectiva = response.data[0].accionCorrectiva;
                    me.dataReporteNC.fechaObjetivo = response.data[0].fechaObjetivo;
                    me.dataReporteNC.lugarAuditoria = JSON.parse(response.data[0].lugarAuditoria);

                    me.dataReporteNC.tAuditor = response.data[0].tAuditor;
                    me.dataReporteNC.nombreFirma1 = response.data[0].nombreFirma1;
                    me.dataReporteNC.urlFirma1 = response.data[0].urlFirma1;
                    me.dataReporteNC.tFecha2 = response.data[0].tFecha2;
                    me.dataReporteNC.nombreFirma2 = response.data[0].nombreFirma2;
                    me.dataReporteNC.urlFirma2 = response.data[0].urlFirma2;
                    me.dataReporteNC.verificacionAccionCorrectiva = response.data[0].verificacionAccionCorrectiva;
                    me.dataReporteNC.verificadaPor = response.data[0].verificadaPor;
                    me.dataReporteNC.nombreFirma3 = response.data[0].nombreFirma3;
                    me.dataReporteNC.urlFirma3 = response.data[0].urlFirma3;
                    me.dataReporteNC.tFecha3 = response.data[0].tFecha3;
                    me.dataReporteNC.ncrCerrado = response.data[0].ncrCerrado;
                    me.dataReporteNC.nota = response.data[0].nota;

                    if (me.$route.params.eu && me.$route.query.idAuditoria) {
                        me.dataReporteNC.isPack = true;
                        me.dataReporteNC.idReporteNC = me.$route.params.eu == 'u' ? me.$route.params.id : null;
                        me.dataReporteNC.idAuditoria = me.$route.query.idAuditoria || null
                        me.dataReporteNC.idUsuario = me.$store.state.user.uid;
                        me.dataReporteNC.sUsuario = me.$store.state.user.username;
                        me.dataReporteNC.eu = me.$route.params.eu == 'u' ? 'u' : 'i'
                    }
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!' + error)
                    me.$router.push({
                        name: me.$route.params.eu ?
                            'ejecutar auditoria' : 'Documentos Stage 2',
                    });
                });
        },
        registrarReportNC() {

            let me = this;
            me.disabled = true;

            const formData = new FormData();

            formData.append("dataReporteNC", JSON.stringify(me.dataReporteNC));
            formData.append("idCliente", me.dataReporteNC.idCliente);
            formData.append("file1", me.firma1);
            formData.append("file2", me.firma2);
            formData.append("file3", me.firma3);

            formData.append("folder", 'pack/documentos-stage-2/registrar-reporte-nc-s2');

            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "cliente/pack/registrar-reporte-nc-s2",
                    formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            Authorization: `Bearer ${localStorage.token}`
                        }
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.$router.push({
                        name: me.$route.params.eu ?
                            'ejecutar auditoria' : 'Documentos Stage 2',
                    });
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!' + error)
                });

        },

        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        },

    },

    async mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.dataReporteNC.idCliente = user.uidClient;
            // this.listarClientes();
            if (this.$route.params.id) {
                this.obtenerReporteNCS2();
            }
        }

    }

}
</script>

<style>
.valign-middle {
    vertical-align: middle !important;
}

.disabledTab {
    pointer-events: none;
}
</style>
